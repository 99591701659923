import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EntradasService } from 'src/app/services/entradas.service';
import { EventoService } from 'src/app/services/evento.service';

@Component({
  selector: 'myApp-validacion-manual',
  templateUrl: './validacion-manual.component.html',
  styleUrls: ['./validacion-manual.component.scss'],
})
export class ValidacionManualComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  private subscriptionsEv: Subscription[] = [];
  evento:any;
  ern = '';
  referencia = '';
  entrada:any;
  confirmacion = false;
  constructor(

    private entradaServ: EntradasService,
    private loadingController: LoadingController,
    private eventoServ: EventoService,
    private mdc: ModalController,

  ) { }

  ngOnInit() {}

  unsubsEvento(){
    this.subscriptionsEv.forEach(data =>{
      data.unsubscribe();
    })
  }

  cerrar(){
    this.mdc.dismiss();
  }


  unsubs(){
    this.subscriptions.forEach(data =>{
      data.unsubscribe();
    })
  }
  async obtenerEvento(){

    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: '',
      duration: 1600
    
    });
    await loading.present();


      this.subscriptionsEv.push(
        this.eventoServ.getEvento(this.entrada.codigoEvento).subscribe(data =>{
          if(data){
           this.unsubsEvento();
            this.evento = data;   
          
            this.rebajarDeEvento();
          
          }
          else{
           console.log('no se encontro evento');
          }
        })
      )
    
 
 
  }
  async obtenerEntrada(){
    this.subscriptions.push(
      this.entradaServ.getEntradaTemp(this.ern).subscribe((data:any) =>{
        if(data){
          
          this.unsubs();
          data.numeroReferencia = 'MANUAL';
          data.reference = this.referencia;
          this.entradaServ.add(data, data.codigoEvento);
          this.entrada = data;
          this.confirmacion = true;
          this.obtenerEvento();
          this.entradaServ.crearSubs(data).then(d =>{
            this.entradaServ.borrarTemp(data.codigo);
          });
         
          //alert('Muchas gracias por su compra verifique suc correo electronico para obtener sus entradas');
       
       
        }
        else{
          alert('Error documento no existe intente de nuevo');
          //this.nav.navigateRoot('eventos');


        }
      })
    )
  }

  rebajarDeEvento(){
    ///pasar este bloque a una funcion en la base de datos
  let index = this.evento.fases.findIndex(f => f.fase == this.entrada.fase);
  this.evento.fases[index].cantidad = this.evento.fases[index].cantidad - parseInt(this.entrada.cantidad);
  this.eventoServ.update(this.evento, this.evento.id).then(data=>{

  });
}

}
