import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor( private storage: Storage) { }

    setStorage(strKey: string, value: any) {
      this.storage.set(strKey, value);
    }

    getStorage(strKey: string){
      return this.storage.get(strKey);
    }

    clearStorage(strKey: string){
      this.storage.remove(strKey);
    }
}
